<template>
  <div class="breadcrumbNav">
    <div class="container">
      <el-breadcrumb separator-class=" ">
        <el-breadcrumb-item :replace="false" v-for="item in breadcrumbList" :key="item.name" :to="{path: item.path}">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>

</template>

<script>
import {Breadcrumb,BreadcrumbItem} from "element-ui";
export default {
  name: "breadcrumbNav",
  components:{
    [Breadcrumb.name]:Breadcrumb,
    [BreadcrumbItem.name]:BreadcrumbItem,
  },
  props:{
    breadcrumbList:{
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/base";
@import "../../assets/scss/config";
@import "../../assets/scss/mixin";
/deep/ .el-breadcrumb__item {
  .el-breadcrumb__inner.is-link:hover {
    color: $colorA;
  }
}
/deep/.el-breadcrumb__item {
  &:last-child {
    .el-breadcrumb__inner.is-link:hover {
      color: $colorL1 !important;
    }
  }
}
.breadcrumbNav {
  //background-color: #ffffff;
  font-size: 16px;
  .el-breadcrumb {
    font-size: 16px;
    padding: 24px 0;
    border-bottom: 1px solid #e3e5e9;

  }
}

</style>
